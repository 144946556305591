import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  //static targets = ["input"];

  connect() {
    new Tagify(this.element, {
      whitelist: [],
    });
  }
}
