// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "trix";
import "@rails/actiontext";
import "./trix_links";

// import LocalTime from "local-time";
// LocalTime.start();

document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll("time").forEach((time) => {
    const datetime = time.getAttribute("datetime");
    //const format = time.getAttribute("data-format");
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const format = {
      year: "numeric",
      month: "long",
      day: "2-digit",
    };

    const formattedValue = Intl.DateTimeFormat(locale, format).format(
      new Date(datetime)
    );

    time.setAttribute("title", formattedValue);
    time.innerHTML = formattedValue;
  });
});

// FIX IOS 100vh issue
// based on https://github.com/Faisal-Manzer/postcss-viewport-height-correction
function setViewportProperty(doc) {
  var prevClientHeight;
  var customVar = "--vh";
  function handleResize() {
    var clientHeight = doc.clientHeight;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty(customVar, clientHeight * 0.01 + "px");
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}
window.addEventListener(
  "resize",
  setViewportProperty(document.documentElement)
);
