import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    redirect: String,
  };

  login(event) {
    event.preventDefault();
    const { redirect } = event.params;

    const csrfState = Math.random().toString(36).substring(7);
    let url = "https://open-api.tiktok.com/platform/oauth/connect/";
    url += "?client_key=awl6in36r5g1scfa";
    url += "&scope=user.info.basic,video.list";
    url += "&response_type=code";
    url += `&redirect_uri=${encodeURIComponent(redirect)}`;
    url += "&state=" + csrfState;

    window.location.href = url;
  }
}
