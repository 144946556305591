import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "input"];

  connect() {
    this.inputTarget.addEventListener("change", this.onInputChanged.bind(this));
    this.toggle(this.inputTarget.checked);
  }

  onInputChanged(event) {
    this.toggle(event.target.checked);
  }

  toggle(value) {
    this.submitTarget.disabled = !value;
  }
}
