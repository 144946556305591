import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Import and register all TailwindCSS Components
import { Dropdown, Modal } from "tailwindcss-stimulus-components";
//application.register("alert", Alert);
//application.register("autosave", Autosave);
application.register("dropdown", Dropdown);
application.register("modal", Modal);
//application.register("tabs", Tabs);
//application.register("popover", Popover);
//application.register("toggle", Toggle);
//application.register("slideover", Slideover);

export { application };
