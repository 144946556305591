import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static originalCustomerly = null;

  connect() {
    const body = document.getElementsByTagName("body")[0];
    body.addEventListener(
      "DOMNodeInserted",
      function (e) {
        if (e.relatedNode.id == "customerly-container")
          this.markDivContainerAsPersisten();
      }.bind(this)
    );
  }

  markDivContainerAsPersisten() {
    document
      .querySelector("#customerly-container")
      .setAttribute("data-turbo-permanent", true);
  }
}
