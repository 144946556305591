import { Controller } from "@hotwired/stimulus";
import { createWidget } from "@typeform/embed";

export default class extends Controller {
  //static targets = ["typeform_embeddable"];
  static values = {
    id: String,
    email: String,
    userUuid: String,
    name: String,
    lastName: String,
    nextPath: String,
    brandCallId: String,
    callApplicationId: String,
  };

  markAsCompleted(typeform_response_id) {
    const authenticityToken = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;

    const apiURL = `${window.location.href}/complete_step`;
    const payload = {
      typeform_response_id: typeform_response_id,
    };

    fetch(apiURL, {
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": authenticityToken,
      },
    })
      .then(() => {
        setTimeout(
          function () {
            Turbo.visit(this.nextPathValue, { action: "replace" });
          }.bind(this),
          3000
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  connect() {
    const tags = {
      email: this.emailValue,
      uuid: this.userUuidValue,
      name: this.nameValue,
      last_name: this.lastNameValue,
      brandCallId: this.brandCallIdValue,
      callApplicationId: this.callApplicationIdValue,
    };

    this.computeMaxHeight();
    window.addEventListener("resize", this.computeMaxHeight.bind(this));

    createWidget(this.idValue, {
      container: this.element,
      hideHeaders: true,
      hideFooter: false,
      opacity: 0,
      inlineOnMobile: true,
      keepSession: true,
      hidden: tags,
      onSubmit: (event) => {
        if (event.response_id) this.markAsCompleted(event.response_id);
      },
    });
  }

  computeMaxHeight() {
    this.element.style.height = `calc((var(--vh, 1vh) * 100) - ${this.element.offsetTop}px)`;
  }
}
