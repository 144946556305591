import { Controller } from "@hotwired/stimulus";
import * as filestack from "filestack-js";

export default class extends Controller {
  static targets = [
    "preview",
    "uploadButton",
    "input",
    "filenameInput",
    "mimetypeInput",
  ];
  static values = {
    apikey: String,
    accepts: Array,
    userUuid: String,
    brandCallId: String,
    callApplicationId: String,
    uploadedFilename: String,
    uploadedMimetype: String,
    uploadedAsset: String,
  };
  static client;

  initialize() {
    this.client = filestack.init(this.apikeyValue);
  }

  connect() {
    const tags = {
      uuid: this.userUuidValue,
      brandCallId: this.brandCallIdValue,
      callApplicationId: this.callApplicationIdValue,
    };

    const options = {
      onUploadDone: this.updateForm.bind(this),
      maxSize: 10 * 1024 * 1024,
      maxFiles: 1,
      accept: this.acceptsValue,
      uploadInBackground: false,
      disableTransformer: true,
      displayMode: "overlay",
      lang: "it",
      supportEmail: "hello@huuno.me",
      fromSources: ["local_file_system"],
      storeTo: {
        path: "/" + this.brandCallIdValue + "/"
      },
      uploadConfig: {
        tags: tags,
      }
    };

    const picker = this.client.picker(options);

    this.uploadButtonTarget.addEventListener("click", function (e) {
      e.preventDefault();
      picker.open();
    });

    this.renderPreview(
      this.uploadedMimetypeValue,
      this.uploadedFilenameValue,
      this.uploadedAssetValue
    );
  }

  renderPreview(mimetype, filename, asset) {
    if (!asset || asset.length == 0) return;

    const isImage = mimetype.split("/")[0] == "image";
    const imagePreview = isImage
      ? `<img class="mx-auto mb-2" src="${asset}" width="100" />`
      : "";

    this.previewTarget.innerHTML = `
    ${imagePreview}
      <div class="inline-flex mb-5 items-center px-2.5 py-0.5  mt-1 rounded-md text-sm font-medium bg-green-100 text-green-800">
        <svg xmlns="http://www.w3.org/2000/svg" class="-ml-0.5 mr-1.5 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <a href="${asset}" target="_blank">${filename}</a>
      </div>
    `;
  }

  updateForm(result) {
    console.debug("updateForm", result);
    const fileData = result.filesUploaded[0];
    this.inputTarget.value = fileData.url;
    this.mimetypeInputTarget.value = fileData.mimetype;
    this.filenameInputTarget.value = fileData.filename;
    this.renderPreview(fileData.mimetype, fileData.filename, fileData.url);
  }
}
