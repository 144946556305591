import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    redirect: String,
  };

  login(event) {
    event.preventDefault();
    const { redirect } = event.params;

    const version = "v9.0";
    const app = "517361649162700";
    const scopes =
      "instagram_basic,instagram_manage_insights,pages_show_list,public_profile,pages_read_engagement,pages_manage_metadata";

    const currentLocation = window.location.href;
    const url = `https://www.facebook.com/${version}/dialog/oauth?client_id=${app}&redirect_uri=${redirect}&auth_type=rerequest&scope=${scopes}&state=${currentLocation}`;
    //console.debug(url);
    window.location.href = url;
  }
}
